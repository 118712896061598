html,
body {
  height: 100vh;
  width: 100%;
  font-family: '-apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'sans-serif', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  min-height: 10vh;
  min-width: 100%;
  font-size: 1.6rem;
}

/* no override method available in global theme*/
.MuiAutocomplete-option {
  font-size: 1.4rem;
}

/* carousel slider css override*/
.rec.rec-arrow {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 0;
  font-size: 1.4rem;
}
.rec-arrow:hover,
.rec-arrow:focus {
  background-color: #4540bb !important;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey !important;
}
.rec-dot_active {
  background-color: #4540bb !important;
  box-shadow: 0 0 1px 3px #8885c7 !important;
}
